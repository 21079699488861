<template>
  <div class="notification" @click="toggleNofification">
    <v-badge
        color="pink"
        :content="$store.state.notifLength"
        :value="$store.state.notifLength"
        class="badge"
        v-if="$store.state.notifLength != ''"
      >
      <Icons icon="bell" color="#00A4B3"/>
    </v-badge>
    <Icons icon="bell" color="#00A4B3" v-else/>
    <!-- detail -->
    <Popup v-if="$store.state.menuActive == 'notif'">
      <notif-detail />
    </Popup>
  </div>
</template>

<script>
import Popup from "@/components/headers/Popup.vue";
import NotifDetail from "@/components/headers/NotifDetail.vue";
import Icons from "@/components/elements/Icons.vue";
// import API from "@/service/api_service";
// import Auth from "@/service/auth_service";
export default {
  components: { Popup, NotifDetail, Icons },
  data: () => ({
    notifCount: '',
  }),
  // mounted() {
  //     API.get(`${process.env.VUE_APP_API_TRUST}notifications/get?per_page=10&page=1`, {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${Auth.getToken()}`,
  //     }).then(res => {
  //         const filter = res.results.filter(res => {
  //           return res.is_read == false;
  //         });
  //         this.$store.state.notifLength = filter.length;
  //     });
  // },
  methods: {
    toggleNofification() {
      this.$store.commit("toggleMenuActive", "notif");
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  cursor: pointer;
  position: relative;
  display: flex;
  //width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  // background: white;
  border-radius: 12px;
}
</style>
<style lang="scss">
.notification .v-badge__badge {
  border-radius: 130px !important;
  font-size: 8px !important;
  height: 17px !important;
  min-width: 15px !important;
}
</style>
