var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-card",class:{
    'user-card--collapse': _vm.hideDetail,
    'user-card--patient': _vm.type == 'patient',
  }},[_c('div',{staticClass:"user-card__avatar",on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"d-flex"},[(_vm.type == 3 || _vm.type == 4 || _vm.type == 5)?_c('img',{attrs:{"src":_vm.profile.urlImage != null
            ? _vm.profile.urlImage
            : require('@/assets/profile_icon@2x.png')}}):_c('img',{attrs:{"src":_vm.profile.urlImage != null
          ? _vm.profile.urlImage
          : require('@/assets/profile_icon@2x.png')}})]),_c('div',{staticClass:"avatar__notif",class:{ 'avatar__notif--active': _vm.notif !== '' }},[_vm._v(" "+_vm._s(_vm.notif)+" ")])]),_c('div',{staticClass:"user-card__info",on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',[_c('div',{staticClass:"user-card__name"},[_vm._v(" "+_vm._s(_vm.profile.name)+" ")]),(_vm.type == 2 || _vm.type == 3 || _vm.type == 4 || _vm.type == 5)?_c('div',{staticClass:"user-card__email"},[_vm._v(" "+_vm._s(_vm.profile.email)+" ")]):_vm._e(),(_vm.type == 1)?[(_vm.profile.verified == true)?_c('div',{staticClass:"user-card__verified d-flex justify-start align-center"},[_c('icons',{staticClass:"pr-1",attrs:{"icon":"icon-verified","color":"#1f3c87"}}),_vm._v(" "+_vm._s(_vm.$t("verified"))+" ")],1):_vm._e(),(_vm.profile.verified == false)?_c('div',{staticClass:"user-card__unverified d-flex justify-start align-center"},[_c('icons',{staticClass:"pr-1",attrs:{"icon":"icon-verified","color":"#D5D5D5"}}),_vm._v(" "+_vm._s(_vm.$t("unverified"))+" ")],1):_vm._e()]:_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }