<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="button" v-bind="attrs" v-on="on">
          <icons :icon="`lang-${$i18n.locale}`" />
          <!-- <p style="margin:0px; text-transform: uppercase;padding-left: 5px; font-size: 1rem;justify-content: center">{{`${$i18n.locale}`}}</p> -->
        </v-btn>
      </template>
      <language-switcher v-slot="{ links }" class="popup popup-language">
        <!-- <v-list class="list"> -->
        <!-- <v-list-item
            v-for="link in links"
            :key="link.langIndex"
          > -->
        <v-row v-for="link in links" :key="link.langIndex" dense>
          <v-col cols="6" class="pa-2">
            <a :href="link.url">
              <v-list-item-title
                ><icons :icon="`lang-${link.langIndex}`"
              /></v-list-item-title>
            </a>
          </v-col>
          <v-col cols="6" class="pa-2">
            <a :href="link.url">
              <v-list-item-title>{{ link.langIndex }}</v-list-item-title>
            </a>
          </v-col>
        </v-row>
        <!-- </v-list-item> -->
        <!--      
        <v-list-item class="list-item">
          <v-col cols="6" class="col-flag">
            <v-list-item-title class="flag"
              ><icons icon="lang-en"
            /></v-list-item-title>
          </v-col>
          <v-col cols="6" class="col-title">
            <v-list-item-title class="title">en</v-list-item-title>
          </v-col>
        </v-list-item>

        <v-list-item class="list-item-bottom">
          <v-col cols="6" class="col-flag">
            <v-list-item-title class="flag"
              ><icons icon="lang-id"
            /></v-list-item-title>
          </v-col>
          <v-col cols="6" class="col-title">
            <v-list-item-title class="title">id</v-list-item-title>
          </v-col>
        </v-list-item> -->

        <!-- <v-list-item class="list-item-bottom">
          <v-col cols="6" class="col-flag">
            <v-list-item-title class="flag"
              ><icons icon="lang-ms"
            /></v-list-item-title>
          </v-col>
          <v-col cols="6" class="col-title">
            <v-list-item-title class="title">ms</v-list-item-title>
          </v-col>
        </v-list-item> -->

        <!-- <v-list-item class="list-item-bottom">
          <v-col cols="6" class="col-flag">
            <v-list-item-title class="flag"
              ><icons icon="lang-th"
            /></v-list-item-title>
          </v-col>
          <v-col cols="6" class="col-title">
            <v-list-item-title class="title">th</v-list-item-title>
          </v-col>
        </v-list-item>

        <v-list-item class="list-item-bottom">
          <v-col cols="6" class="col-flag">
            <v-list-item-title class="flag"
              ><icons icon="lang-ph"
            /></v-list-item-title>
          </v-col>
          <v-col cols="6" class="col-title">
            <v-list-item-title class="title">ph</v-list-item-title>
          </v-col>
        </v-list-item> -->
        <!-- </v-list> -->
      </language-switcher>
    </v-menu>
  </div>
</template>
<script>
import icons from "@/components/elements/Icons.vue";

export default {
  components: {
    icons,
  },
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
.popup {
  padding: 8px;
  background: white;
  text-transform: uppercase;
  font-size: 12px !important;
  line-height: 16px;
  display: flex;
  flex-direction: column-reverse;
  a {
    text-decoration: none;
    color: black;
  }
}
.theme--light.v-btn.v-btn--has-bg {
  background-color: transparent;
}

.button {
  border-radius: 0;
  border-style: none;
  box-shadow: none;
  background-color: transparent;
  // margin-top: 10px;
  margin-left: 20px;
}
.theme--light.v-btn:focus::before {
  opacity: 0;
}
.theme--light.v-btn:hover::before {
  opacity: 0;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 10px;
  padding: 0px 0px;
}

element {
  min-width: 28px;
  top: 58px;
  left: 1172px;
  transform-origin: left top 0px;
  z-index: 8;
}
.v-menu__content {
  position: absolute;
  display: inline-block;
  max-width: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  contain: content;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.09), 0px 3px 14px 2px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
}

.flag {
  margin-left: -22px;
}
.title {
  text-transform: uppercase;
  font-size: 12px !important;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 5px;
  padding-right: -10px;
}

.col-title {
  margin-left: -5px;
  margin-top: -25px;
}

.list-item {
  margin-top: -8px;
  margin-bottom: -7px;
  margin-left: 8px;
}
.list-item-bottom {
  margin-top: -20px;
  margin-bottom: -7px;
  margin-left: 8px;
}

.list {
  width: 75px;
  height: 100px;
  overflow: hidden;
}
</style>
