<template>
  <div class="topbar">
    <div class="topbar__logo">
      <img src="../../../assets/logoDigiLab.png" alt="" />
    </div>
    <div class="topbar__main">
      <user-card
        v-if="$route.path == '/patient'"
        type="patient"
        :hideDetail="true"
        notif="12"
      >
      </user-card>
      <div class="topbar__menus">
        <div class="menus__nav">
          <div @click="() => $router.go(-1)">
            <icons
              icon="arrow-back"
              color="#00A4B3"
              class="d-flex align-center"
              v-if="$router.currentRoute.path != '/patient'"
            />
          </div>
          <localized-link to="/patient" v-if="$route.path != '/patient'">
            <icons icon="house" color="#00a4b3" class="ml-3 d-flex align-baseline" />
          </localized-link>
        </div>
        <heading-page
          :title="$store.state.activePage"
          class="text-center"
          v-if="$route.path != '/patient'"
        />

        <icons class="cart d-flex align-center" icon="cart" color="#00a4b3" />
      </div>
    </div>
  </div>
</template>

<script>
import UserCard from "@/components/v2/sidebar/UserCard.vue";
import Icons from "@/components/elements/Icons.vue";
import HeadingPage from "@/components/Heading/HeadingPage.vue";
export default {
  components: {
    UserCard,
    Icons,
    HeadingPage,
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  display: none;
  flex-direction: column;
  width: 100%;
  @media screen and (max-width: 960px) {
    display: flex;
  }

  &__main {
    display: flex;
    align-items: center;
    margin: 8px 0;
  }
  &__logo {
    img {
      height: 30px;
    }
  }
  &__menus {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .menus {
      &__nav {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
}

  @media screen and (max-width: 1270px) {
    .topbar{
      display: none;
      .topbar__logo{
        display: none;
      }

      .topbar__menus{
        display: none;
      }

      .user-card{
        display: none;
      }
    }
  }
</style>
