<template>
  <div class="popup">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.popup {
  position: absolute;
  min-width: 200px;
  //right:0px;
  right: -20px;
  //top: 78px;
  top: 50px;
  border-radius: 14px;
  box-shadow: 0px 0px 8px #cbc9c9;
  background: white;
  z-index: 100;
  &::before {
    content: "";
    width: 16px;
    height: 16px;
    position: absolute;
    top: -6px;
    transform: rotate(45deg);
    //right: 16px;
    right: 22px;
    background: white;
  }
}
</style>
