<template>
  <h1>{{ title }}</h1>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/color.scss";

h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: $main_1;
}
</style>
