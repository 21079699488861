<template>
  <div>
    <!-- <div
      class="tab__item"
      :class="{ 'tab__item--active': accountActive == user.id }"
      v-for="(user, index) of switchAccountList"
      :key="index"
      @click="() => switchAccount(user)"
      > -->
    <v-select
      :class="{
        'tab-hide': hideTitle,
      }"
      class="tab tab--rounded tab--border tab-1024"
      v-model="selectedAccount"
      :items="switchAccountList"
      item-text="fullName"
      item-value="id"
      @change="() => switchAccount()"
      solo
      hide-details
      dense
      prepend-icon="mdi-account-multiple"
    >
      <!-- @change="() => switchAccount(switchAccountList.id)" -->
      <!-- <template v-slot:option="option"> -->
      <!-- {{ option.fullName }} -->
      <!-- </template> -->
      <!-- .toLowerCase() -->
    </v-select>
    <!-- </div> -->

    <!-- modal company code -->
    <v-dialog v-model="dialogCompanyCode" max-width="500px">
      <v-form
        ref="formReject"
        lazy-validation
        v-on:submit.prevent="saveCompanyCode"
      >
        <v-card>
          <v-card-title>{{ $t("field.input_company_code") }}</v-card-title>
          <v-divider class="mt-1"></v-divider>
          <v-card-text style="padding-bottom: 3px !important">
            <p style="color: red" v-if="showErr == true">
              {{ this.errorMessages }}
            </p>
            <v-text-field
              class="mt-0 uppercase-input fontSet"
              v-model="companyCode"
              :rules="[
                v => !!v || `${$t('field.company_code')} ${$t('is_required')}`,
              ]"
              :label="$t('field.company_code')"
              outlined
              required
              dense
            ></v-text-field>
          </v-card-text>
          <v-divider class="mt-1"></v-divider>
          <v-card-actions class="justify-end">
            <v-btn text @click="closeEdit"> Cancel </v-btn>
            <v-btn color="#00a4b3" text @click="saveCompanyCode"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    
    <!-- modal health facility code -->
    <v-dialog v-model="dialogHealthCode" max-width="500px">
      <v-form
        ref="formReject"
        lazy-validation
        v-on:submit.prevent="saveHealthCode"
      >
        <v-card>
          <v-card-title>{{ $t("field.input_health_code") }}</v-card-title>
          <v-divider class="mt-1"></v-divider>
          <v-card-text style="padding-bottom: 3px !important">
            <p style="color: red" v-if="showErr == true">
              {{ this.errorMessages }}
            </p>
            <v-text-field
              class="mt-0 uppercase-input fontSet"
              v-model="healthCode"
              :rules="[
                v => !!v || `${$t('field.health_code')} ${$t('is_required')}`,
              ]"
              :label="$t('field.health_code')"
              outlined
              required
              dense
            ></v-text-field>
          </v-card-text>
          <v-divider class="mt-1"></v-divider>
          <v-card-actions class="justify-end">
            <v-btn text @click="closeEdit"> Cancel </v-btn>
            <v-btn color="#00a4b3" text @click="saveHealthCode"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import API from "../../../service/api_service";
import Auth from "../../../service/auth_service";
export default {
  data: () => ({
    switchAccountList: [],
    accountActive: null,
    selectedAccount: "",
    dialogCompanyCode: false,
    dialogHealthCode: false,
    editedIndex: -1,
    errorMessages: "",
    companyCode: "",
    healthCode: "",
    showErr: false,
  }),
  props: {
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // get data profile from store
    dataProfile() {
      return this.$store.state.profileData;
    },
  },
  watch: {
    dialogCompanyCode(val) {
      val || this.closeEdit();
    },
    dialogHealthCode(val){
      val || this.closeEdit();
    }
  },
  created() {
    this.accountActive = Auth.getTypeUser();
    this.getAccount();
    if (this.dataProfile) {
      this.getActiveAccount();
    }
  },
  methods: {
    async getActiveAccount() {
      try {
        this.switchAccountList = [
          ...this.switchAccountList,
          {
            type: this.dataProfile.type,
            id: this.checkTypeUser(this.dataProfile.type),
            fullName: this.getTypeName(this.dataProfile.type),
          },
        ];
        // sort switchAccountList
        const ascAccount = this.switchAccountList.sort((a, b) => {
          return a.id - b.id;
        });
        this.switchAccountList = ascAccount;
        const accountId = this.switchAccountList.findIndex(
          res => this.accountActive == res.id
        );
        this.selectedAccount = this.switchAccountList[accountId];
      } catch (error) {
        console.log(error);
      }
    },
    checkTypeUser(type) {
      if (type == "HEALTH FACILITY") {
        return 6;
      }
      // else if (type == "PAYOR_HRDMGR") {
      //   return 5;
      // }
      // else if (type == "PAYOR_FINANCE") {
      //   return 4;
      // }
      else if (type == "PAYOR") {
        return 3;
      } else if (type == "REFERRER") {
        return 2;
      } else {
        return 1;
      }
    },
    async getAccount() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}account-switch`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode == 404) {
          this.$router.push(`/${this.$i18n.locale}/sign-out`);
        }

        const respId = resp.results.map(item => {
          item.id = this.checkTypeUser(item.type);
          item.fullName = this.getTypeName(item.type);
          return item;
        });

        this.switchAccountList = [...this.switchAccountList, ...respId];
      } catch (error) {
        this.$router.push(`/${this.$i18n.locale}/sign-out`);
        console.log(error);
      }
    },
    async switchAccount() {
      if (this.selectedAccount != this.accountActive) {
        const userIndex = this.switchAccountList.findIndex(
          res => this.selectedAccount == res.id
        );

        if (this.selectedAccount == 3) {
          this.selected = this.switchAccountList[userIndex];
          this.dialogCompanyCode = true;
        } else if(this.selectedAccount == 6){
          this.selected = this.switchAccountList[userIndex];
          this.dialogHealthCode = true;
        }
        else {
          this.$store.commit("setLoading", true);
          try {
            const resp = await API.post(
              `${process.env.VUE_APP_API_TRUST}account-switch`,
              {
                Authorization: `Bearer ${Auth.getToken()}`,
                "Content-Type": "application/json",
              },
              {
                uuid: this.switchAccountList[userIndex].uuid,
              }
            );
            if (resp.statusCode == 404) {
              this.$router.push(`/${this.$i18n.locale}/sign-out`);
            } else {
              Auth.signOut();
              if (!resp.results.fully_registered) {
                Auth.setTypeUser(0);
                Auth.setToken(
                  resp.results.access_token,
                  moment().add(1, "hours")
                );
                this.$store.commit("setUserType", 0);
                this.$router.push(`/${this.$i18n.locale}/continue-register`);
              } else {
                Auth.setToken(resp.results.access_token);
                if (resp.results.type == "HEALTH FACILITY") {
                  Auth.setTypeUser(6);
                  this.$store.commit("setUserType", 6);
                  window.location.href = `/${this.$i18n.locale}/medical-center`;
                }
                // else if (resp.results.type == "PAYOR_HRDMGR") {
                //   Auth.setTypeUser(5);
                //   this.$store.commit("setUserType", 5);
                //   window.location.href = `/${this.$i18n.locale}/company`;
                // }
                // else if (resp.results.type == "PAYOR_FINANCE") {
                //   Auth.setTypeUser(4);
                //   this.$store.commit("setUserType", 4);
                //   window.location.href = `/${this.$i18n.locale}/company`;
                // }
                else if (resp.results.type == "PAYOR") {
                  Auth.setTypeUser(3);
                  this.$store.commit("setUserType", 3);
                  window.location.href = `/${this.$i18n.locale}/company`;
                } else if (resp.results.type == "REFERRER") {
                  Auth.setTypeUser(2);
                  this.$store.commit("setUserType", 2);
                  window.location.href = `/${this.$i18n.locale}/doctor`;
                } else {
                  Auth.setTypeUser(1);
                  this.$store.commit("setUserType", 1);
                  window.location.href = `/${this.$i18n.locale}/patient/profile`;
                }
              }
            }
          } catch (error) {
            this.$store.commit("setLoading", false);
            console.log(error);
          }
        }
      }
    },
    getTypeName(type) {
      if (type == "HEALTH FACILITY") {
        return this.$t("medical_center");
      }
      // else if (type == "PAYOR_HRDMGR") {
      //   return this.$t("company");
      // }
      // else if (type == "PAYOR_FINANCE") {
      //   return this.$t("company");
      // }
      else if (type == "PAYOR") {
        return this.$t("company");
      } else if (type == "REFERRER") {
        return this.$t("doctor");
      } else {
        return this.$t("patient");
      }
    },
    async saveCompanyCode(e) {
      if (this.$refs.formReject.validate() == true) {
        e.preventDefault();
        try {
          const resp = await API.post(
            `${process.env.VUE_APP_API_TRUST}account-switch`,
            {
              Authorization: `Bearer ${Auth.getToken()}`,
              "Content-Type": "application/json",
            },
            {
              company_code: this.companyCode,
              uuid: this.selected.uuid,
            }
          );
          if (resp.statusCode == 404) {
            this.$store.commit("setLoading", true);
            this.$router.push(`/${this.$i18n.locale}/sign-out`);
          } else if (resp.statusCode == 401) {
            this.showErr = true;
            this.errorMessages = resp.message;
          } else {
            this.$store.commit("setLoading", true);
            Auth.signOut();
            if (!resp.results.fully_registered) {
              Auth.setTypeUser(0);
              Auth.setToken(
                resp.results.access_token,
                moment().add(1, "hours")
              );
              this.$store.commit("setUserType", 0);
              this.$router.push(`/${this.$i18n.locale}/continue-register`);
            } else {
              Auth.setToken(resp.results.access_token);
              if (resp.results.type == "HEALTH FACILITY") {
                Auth.setTypeUser(6);
                this.$store.commit("setUserType", 6);
                window.location.href = `/${this.$i18n.locale}/medical-center`;
              } else if (resp.results.type == "PAYOR") {
                Auth.setTypeUser(3);
                this.$store.commit("setUserType", 3);
                window.location.href = `/${this.$i18n.locale}/company`;
              } else if (resp.results.type == "REFERRER") {
                Auth.setTypeUser(2);
                this.$store.commit("setUserType", 2);
                window.location.href = `/${this.$i18n.locale}/doctor`;
              } else {
                Auth.setTypeUser(1);
                this.$store.commit("setUserType", 1);
                window.location.href = `/${this.$i18n.locale}/patient/profile`;
              }
            }
          }
        } catch (error) {
          this.$store.commit("setLoading", false);
          console.log(error);
        }
      }
    },
    async saveHealthCode(e) {
      if (this.$refs.formReject.validate() == true) {
        e.preventDefault();
        try {
          const resp = await API.post(
            `${process.env.VUE_APP_API_TRUST}account-switch`,
            {
              Authorization: `Bearer ${Auth.getToken()}`,
              "Content-Type": "application/json",
            },
            {
              company_code: this.healthCode,
              uuid: this.selected.uuid,
            }
          );
          if (resp.statusCode == 404) {
            this.$store.commit("setLoading", true);
            this.$router.push(`/${this.$i18n.locale}/sign-out`);
          } else if (resp.statusCode == 401) {
            this.showErr = true;
            this.errorMessages = resp.message;
          } else {
            this.$store.commit("setLoading", true);
            Auth.signOut();
            if (!resp.results.fully_registered) {
              Auth.setTypeUser(0);
              Auth.setToken(
                resp.results.access_token,
                moment().add(1, "hours")
              );
              this.$store.commit("setUserType", 0);
              this.$router.push(`/${this.$i18n.locale}/continue-register`);
            } else {
              Auth.setToken(resp.results.access_token);
              if (resp.results.type == "HEALTH FACILITY") {
                Auth.setTypeUser(6);
                this.$store.commit("setUserType", 6);
                window.location.href = `/${this.$i18n.locale}/medical-center`;
              } else if (resp.results.type == "PAYOR") {
                Auth.setTypeUser(3);
                this.$store.commit("setUserType", 3);
                window.location.href = `/${this.$i18n.locale}/company`;
              } else if (resp.results.type == "REFERRER") {
                Auth.setTypeUser(2);
                this.$store.commit("setUserType", 2);
                window.location.href = `/${this.$i18n.locale}/doctor`;
              } else {
                Auth.setTypeUser(1);
                this.$store.commit("setUserType", 1);
                window.location.href = `/${this.$i18n.locale}/patient/profile`;
              }
            }
          }
        } catch (error) {
          this.$store.commit("setLoading", false);
          console.log(error);
        }
      }
    },
    closeEdit() {
      this.errorMessages = "";
      this.formHasErrors = false;
      this.dialogCompanyCode = false;
      this.dialogHealthCode = false;
      this.selectedAccount = this.accountActive;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
  },
};
</script>
<style>
.tab > .v-input__control > .v-input__slot {
  background: unset !important;
  box-shadow: unset !important;
  padding-left: 0 !important;
}
.tab
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > .v-input__append-inner
  > .v-input__icon
  > .theme--light.v-icon {
  color: white !important;
}
.tab
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > .v-select__selections
  > .v-select__selection--comma {
  color: white !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-transform: capitalize;
}
.tab > .v-input__prepend-outer {
  margin: 7px 10px 0 10px !important;
}
.tab > .v-input__prepend-outer > .v-input__icon > .v-icon {
  color: white;
}

@media screen and (width: 1024px) {
  .tab-1024 {
    width: 60% !important;
    margin-left: 15px !important;
  }
  .tab-1024 > .v-input__control {
    margin-left: -20px !important;
  }
  .tab-1024
    > .v-input__control
    > .v-input__slot
    > .v-select__slot
    > .v-select__selections {
    display: none !important;
  }
  .tab-1024 > .v-input__prepend-outer {
    margin: 7px 10px 0 5px !important;
  }

  .tab-1024
    > .v-input__control
    > .v-input__slot
    > .v-select__slot
    > .v-input__append-inner
    > .v-input__icon
    > .theme--light.v-icon {
    color: #00a4b3 !important;
    margin-top: 5px;
  }
  .tab-1024 > .v-input__prepend-outer > .v-input__icon > .v-icon {
    color: #00a4b3 !important;
  }
  .tab-1024
    > .v-input__control
    > .v-input__slot
    > .v-select__slot
    > .v-input__append-inner
    > .v-input__icon
    > .theme--light.v-icon {
    color: #00a4b3 !important;
    margin-top: 5px;
  }
  .tab {
    background: unset !important;
    border: unset !important;
  }
}

.tab-hide {
  width: 60% !important;
  margin-left: 15px !important;
}
.tab-hide > .v-input__control {
  margin-left: -20px !important;
}
.tab-hide
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > .v-select__selections {
  display: none !important;
}
.tab-hide > .v-input__prepend-outer {
  margin: 10px 10px 0 15px !important;
}
.tab-hide > .v-input__prepend-outer > .v-input__icon > .v-icon {
  color: #00a4b3 !important;
}
.tab-hide
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > .v-input__append-inner
  > .v-input__icon
  > .theme--light.v-icon {
  color: #00a4b3 !important;
  margin-top: 5px;
}
.tab-hide {
  background: unset !important;
  border: unset !important;
}
</style>
>
<style lang="scss" scoped>
@import "../../../assets/scss/color.scss";

.tab {
  display: flex;
  // margin: 12px 12px;
  border: none;
  //background: white;
  background: #00a4b3;
  color: white;
  overflow: hidden;
  width: 90%;
  @media screen and (max-width: 960px) {
    margin: 0px;
  }
  &__item {
    //item
    background: white;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: gray;
    cursor: pointer;
    border-radius: 5px;
    box-sizing: border-box;
    &:hover {
      background: $main_2;
      color: white;
    }
    &--active {
      //item active
      background: $main_2;
      color: white;
      cursor: default;
    }
  }
  &--rounded {
    border-radius: 5px;
  }
  &--shadow {
    box-shadow: 0px 0px 2px $main_2;
  }
  &--border {
    border: 1px solid $gray_2;
  }
}
</style>
