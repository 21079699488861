<template>
  <div v-click-outside="closeMenu" class="notif-scroll">
    <div class="header">
      <h4>{{ $t("notification") }}</h4>
      <!-- <localized-link to="/dashboard/notification">
        {{ $t("view_all") }}
      </localized-link> -->
    </div>
    <v-row class="ma-4" dense v-if="this.$store.getters.getNotification.length > 0">
      <v-col
        cols="12 mb-2"
        v-for="(item, index) of this.$store.getters.getNotification"
        :key="index"
        :class="{ unread: !item.is_read }"
      >
        <v-card color="#FFF" class="inside">
          <!-- to="/patient/my-order" -->
          <div v-if="userType == 3">
            <localized-link
              style="text-decoration: none; color: inherit"
              to="/company/medical-record"
              @click.native="readNotificationCompany(item.notification_id, item.type)"
            >
              <v-row class="" dense>
                <v-col cols="1">
                  <v-card-title class="pb-0 pt-3 text-title">
                    <icons
                      icon="bell"
                      class="menu__icon float-right"
                      color="#000"
                      style="margin-left: 140%"
                    />
                  </v-card-title>
                </v-col>
                <v-col cols="11">
                  <v-card-title class="pb-0 pt-3 text-title mb-2 text-capitalize">
                    {{
                      item[`title_${$i18n.locale}`] == null
                        ? item[`title_en`]
                        : item[`title_${$i18n.locale}`]
                    }}
                  </v-card-title>
                  <v-card-text class="pb-0 mb-0 text-card text-capitalize">
                    {{
                      item[`body_${$i18n.locale}`] == null
                        ? item[`body_en`]
                        : item[`body_${$i18n.locale}`]
                    }}
                  </v-card-text>
                  <v-card-subtitle class="pt-2 mt-0 text-caption">
                    <span class="grey--text">{{
                      formatDate(item.created_at)
                    }}</span>
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </localized-link>
          </div>
          <div v-else>
            <localized-link
              style="text-decoration: none; color: inherit"
              to="/patient/profile"
              @click.native="readNotification(item.notification_id, item.type)"
            >
              <v-row class="" dense>
                <v-col cols="1">
                  <v-card-title class="pb-0 pt-3 text-title">
                    <icons
                      icon="bell"
                      class="menu__icon float-right"
                      color="#000"
                      style="margin-left: 140%"
                    />
                  </v-card-title>
                </v-col>
                <v-col cols="11">
                  <v-card-title class="pb-0 pt-3 text-title mb-2 text-capitalize">
                    {{
                      item[`title_${$i18n.locale}`] == null
                        ? item[`title_en`]
                        : item[`title_${$i18n.locale}`]
                    }}
                  </v-card-title>
                  <v-card-text class="pb-0 mb-0 text-card text-capitalize">
                    {{
                      item[`body_${$i18n.locale}`] == null
                        ? item[`body_en`]
                        : item[`body_${$i18n.locale}`]
                    }}
                  </v-card-text>
                  <v-card-subtitle class="pt-2 mt-0 text-caption">
                    <span class="grey--text">{{
                      formatDate(item.created_at)
                    }}</span>
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </localized-link>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else class="ma-0">
      <v-col cols="12">
        <p class="text-center">{{ $t("there_not_update_yet") }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import IconTicket from "../elements/IconTicket.vue"
import Auth from "@/service/auth_service";
import API from "@/service/api_service";
import icons from "@/components/elements/Icons.vue";
import moment from "moment";

export default {
  components: { icons },
  // setup() {
  //   const readNotification = (event: MouseEvent, navigate: (event: MouseEvent) => void) => {
  //     console.log

  //     navigate(event);

  //   };

  //   return {
  //     readNotification
  //   };
  // },
  data: function () {
    return {
      notification_list: [],
      uuid : "",
      userType: "",
    };
  },
  created(){
    // this.getNotification();

  }, 
  mounted(){
    this.userType = Auth.getTypeUser();
  },
  methods: {
    // async getNotification() {
    //   let resp = await API.get(
    //     `${process.env.VUE_APP_API_TRUST}notifications/get?per_page=10&page=1`,
    //     {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${Auth.getToken()}`,
    //     }
    //   );
    //   this.notification_list = resp.results;
    // },
    async readNotificationCompany(notif_id, type) {
      var form = {
        notification_id: [notif_id],
      };

      let resp = await API.post(
        `${process.env.VUE_APP_API_TRUST}notifications/mark-as-read`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        form
      );
      if (resp) {
        if (type.includes("SHARING")) {
          this.$router
            .push(`/${this.$i18n.locale}/company/medical-record?tab=SHARE`)
            .catch(() => {});
         
        } else {
          this.$router
            .push(`/${this.$i18n.locale}/company/medical-record?tab=COMPANY`)
            .catch(() => {});
         
        }
      }
      this.$store.dispatch("fetchNotification");
     
    },

    async readNotification(notif_id, type) {
      var form = {
        notification_id: [notif_id],
      };

      let resp = await API.post(
        `${process.env.VUE_APP_API_TRUST}notifications/mark-as-read`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        form
      );
      if (resp) {
        if (type.includes("FAMILY")) {
          this.$router
            .push(`/${this.$i18n.locale}/patient/profile?tab=${type}`)
            .catch(() => {});
          //this.$store.commit("setActiveTab", 3);
        } else {
          this.$router
            .push(`/${this.$i18n.locale}/patient/profile?tab=${type}`)
            .catch(() => {});
          //this.$store.commit("setActiveTab", 6);
        }
      }
      this.$store.dispatch("fetchNotification");
      // if (resp) {
      //   this.$router
      //     .push(`/${this.$i18n.locale}/patient/my-order`)
      //     .catch(() => {});
      // } else {
      //   this.$router
      //     .push(`/${this.$i18n.locale}/patient/my-order`)
      //     .catch(() => {});
      // }
      // API.get(
      //   `${process.env.VUE_APP_API_TRUST}notifications/get?per_page=10&page=1`,
      //   {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${Auth.getToken()}`,
      //   }
      // ).then(res => {
      //   const filter = res.results.filter(res => {
      //     return res.is_read == false;
      //   });
      //   this.$store.state.notifLength = filter.length;
      // });
    },
    closeMenu() {
      this.$store.commit("toggleMenuActive", "");
    },
    formatDate(date) {
      return moment(date).locale(this.$i18n.locale).format("DD-MMM-YYYY HH:mm:ss").toUpperCase();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/custom.scss";
.header {
  cursor: default;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  width: 450px;
  font-weight: 500;
  padding: 18px;
  border-bottom: 1px solid $gray_3;
  h4 {
    color: $blue_1;
  }
  a {
    text-decoration: none;
    color: $blue_2;
  }
}
ul {
  cursor: default;
  list-style: none;
  padding: 0 0 12px 0;
  color: $gray_1;
  overflow: hidden;
  li {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    position: relative;
    border-bottom: 1px solid $gray_3;
    margin: 0 10px 0 25px;
    font-size: 14px;
    padding: 0 0 0 10px;
    .icon {
      display: flex;
      height: 100%;
      align-items: center;
    }
    h4 {
      margin-top: 8px;
    }
    p {
      margin-bottom: 8px;
    }
    &.unread {
      &::before {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        left: -16px;
        top: 50%;
        transform: translateY(-50%);
        background: $blue_2;
        border-radius: 50%;
      }
    }
  }
}
.text-title {
  font-size: 14px !important;
  word-break: break-word;
  line-height: 18px;
  font-weight: normal !important;
}
.text-card {
  line-height: 18px;
  color: rgb(68, 67, 67);
  font-size: 14px;
}
.notif-scroll {
  max-height: 500px;

  overflow-y: scroll;
}
.unread > .inside {
  background: rgba(255, 255, 159, 0.3) !important;
}

.unread .text-title {
  font-weight: bold !important;
}
</style>
